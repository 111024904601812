import {appendBody, loadCss, loadFonts, appendScript, isPost, isHomepage} from "./helpers.js";
import {HOMEPAGE_CTA} from "./const.js";

class RenderBase {

    constructor(options, site, data, member) {
        this.options = options
        this.site = site
        this.data = data
        this.member = member
    }

    loadAssets(key, item) {
        if (item.css) {
            loadCss(`outpost-box${key}-style`, item.css);
        }
        if (item.fonts) {
            loadFonts(item.fonts);
        }
        if (item.body) {
            appendBody(item.body);
        }
        if (item.script) {
            appendScript(item.script);
        }
    }

    attachSubscriptionFormEvents() {
        Array.from(document.querySelectorAll('form[data-outpost-members-form]')).forEach(form => {
            const errorEl = form.querySelector('[data-members-error]');
            const site = this.site;
            const submitHandler = event => this.outpostGhostSubscribeBoxHandler({ event, errorEl, form, site, submitHandler });

            form.addEventListener('submit', submitHandler);
        });
    }

    outpostGhostSubscribeBoxHandler({ event, form, errorEl, site, submitHandler }) {
        form.removeEventListener('submit', submitHandler);
        event.preventDefault();

        this.resetFormClasses(form, errorEl);

        const emailInput = event.target.querySelector('input[data-members-email]');
        const nameInput = event.target.querySelector('input[data-members-name]');
        const email = emailInput?.value;
        const name = nameInput?.value;

        const labels = Array.from(event.target.querySelectorAll('input[data-members-label]') || []).map(input => input.value);

        form.classList.add('loading');

        this.submitSubscribeForm({ email, labels, name, form, errorEl, site, submitHandler });
    }

    resetFormClasses(form, errorEl) {
        if (errorEl) {
            errorEl.innerText = '';
        }
        form.classList.remove('success', 'invalid', 'error');
    }

    submitSubscribeForm({ email, labels, name, form, errorEl, site, submitHandler }) {
        const reqBody = { email, labels, name };

        fetch(`${site.url}/members/api/integrity-token/`, {
            method: 'GET'
        }).then((res) => {
            return res.text();
        }).then((integrityToken) => {

            // do main send magic link request with integrity token
            fetch(`${site.url}members/api/send-magic-link/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    ...reqBody,
                    integrityToken
                }),
            })
                .then(res => {
                    form.addEventListener('submit', submitHandler);
                    form.classList.remove('loading');

                    if (res.ok) {
                        form.classList.add('success');
                    }

                    // check if we need to hide form, let's do this
                    if (this.data.settings) {
                        const settings = this.data.settings;
                        if (settings.onSubmit && settings.onSubmit.hideForm) {
                            const ctaContent = form.parentNode
                            const heading = ctaContent.querySelector('.outpost-cta-heading')
                            const signIn = ctaContent.querySelector('.outpost-cta-signin')

                            heading.innerHTML = `<img src="https://assets.outpostpublishingcoop.com/assets/img/cta/check-mark.png" class="thank-you-img" width="28" height="28" />` + settings.onSubmit.headerText
                            heading.classList.add('thank-you')

                            ctaContent.querySelector('.outpost-cta-subheading').innerHTML = settings.confirmationText
                            form.style.display = 'none'

                            if (signIn) {
                                signIn.style.display = 'none'
                            }
                        }
                    }
                })
                .catch(err => {
                    if (errorEl) {
                        errorEl.innerText = 'There was an error sending the email, please try again';
                    }
                    form.classList.add('error');
                });

        });
    }

    filterParagraphs(paragraphs, excludeSelectors) {
        return paragraphs.filter(paragraph => !excludeSelectors.some(sel => paragraph.closest(sel)));
    }

    getPostContentItems(cta) {
        let defaultContentSelector = 'main article:first-of-type';
        if (document.querySelectorAll('.gh-content').length) {
            defaultContentSelector = '.gh-content';
        }

        const contentSelector = (cta.autoplacement && cta.autoplacement.content) || defaultContentSelector;
        const content = document.querySelectorAll(contentSelector);

        const pSelector = (cta.autoplacement && cta.autoplacement.selector) || 'p'

        let paragraphs = Array.from(content[0]?.querySelectorAll(pSelector) || [])
        if (paragraphs.length) {
            // find root container for paragraphs
            paragraphs = Array.from(paragraphs[0].parentNode.querySelectorAll(':scope > *') || [])
        }

        const excludeSelectors = ['.content-cta', '.outpost-pub-container', '.ctx-module-container'];
        return this.filterParagraphs(paragraphs, excludeSelectors);
    }

    isCtaAllowed(box) {
        if (isPost(this.options)) {
            const post = this.options.post;
            return !post.tags || !post.tags.includes('#hide-outpost-cta');
        } else if (isHomepage()) {
            return HOMEPAGE_CTA.includes(box.type);
        }
        return false;
    }

    setupViewObserver(element, callback) {
        let eventExecuted = false;

        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting && !eventExecuted) {
                    eventExecuted = true;
                    observer.unobserve(entry.target);
                    callback();
                }
            });
        });

        observer.observe(element);
    }

    setupClickEvent(element, callback) {
        element.addEventListener("click", callback)
    }

    logPlausibleEvent(eventName) {
        if (typeof plausible === 'undefined') return;
        plausible(eventName)
    }
}

export default RenderBase
